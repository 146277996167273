<template>
  <div>
    <el-row :gutter="10">
      <el-col :xs="12" :sm="9" :md="8" :lg="6" :xl="11">
        <el-select v-model="value" placeholder="请选择">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :xs="12" :sm="9" :md="8" :lg="6" :xl="11">
        <el-select v-model="multipleValue" placeholder="请选择">
          <el-option
              v-for="item in multiple"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-col>

    </el-row>

    <el-row :gutter="10">
      <el-col :xs="12" :sm="9" :md="8" :lg="6" :xl="11">
        <el-input
            placeholder="请输入重播时间"
            v-model="input"
            clearable>
        </el-input>
      </el-col>
      <el-col :xs="12" :sm="9" :md="8" :lg="6" :xl="11">
        是否重播
        <el-switch
            v-model="is_Replay"
            active-color="#13ce66"
            inactive-color="#ff4949">
        </el-switch>
      </el-col>

    </el-row>



    <el-row :gutter="10">
      <el-col class="infinite-list"  style="overflow:auto" :xs="12" :sm="9" :md="8" :lg="6" :xl="11" v-for="item in timeList" v-bind:key="item">
         {{ item }}
      </el-col>
    </el-row>
    <br />
    <!-- 准备一个容器用来存放音乐播放器 -->
    <div id="aplayer"></div>
  </div>
</template>

<script>
import APlayer from 'aplayer';
import "aplayer/dist/APlayer.min.css"; // 引入音乐插件的样式
import {mapState} from  'vuex'

export default {
  name: "Video",
  data() {
    return {
      is_Replay:false,
      input:0,
      timeList:[],
      options: [{
        value: '标准日本语初级单词',
        label: '标准日本语初级单词'
      }, {
        value: '标准日本语初级课文',
        label: '标准日本语初级课文'
      }, {
        value: '耳感记忆N3单词',
        label: '耳感记忆N3单词'
      }],
      value: '标准日本语初级单词',
      index: 1,
      multiple: [{
        value: 0.5,
        label: '0.5 X'
      }, {
        value: 0.75,
        label: '0.75 X'
      },{
        value: 1,
        label: '1 X'
      }, {
        value: 1.5,
        label: '1.5 X'
      }, {
        value: 2,
        label: '2 X'
      }],
      multipleValue: 1,
      info: {
        fixed: true, // 不开启吸底模式
        listFolded: true, // 折叠歌曲列表
        autoplay: false, // 开启自动播放
        preload: "auto", // 自动预加载歌曲
        loop: "one", // 播放循环模式、all全部循环 one单曲循环 none只播放一次
        order: "list", //  播放模式，list列表播放, random随机播放
        listMaxHeight:'400px',
        storageName:'aplayer-setting'
      },
    };
  },
  mounted() {
    var val = localStorage.getItem('VideoListType') ? localStorage.getItem('VideoListType') :  '标准日本语初级单词'
    console.log(localStorage.getItem('VideoListType'))
    this.value = val
    this.$store.dispatch('getVideoList',{'type':val})
  },
  watch:{
    audio:function (val){
      // 初始化播放器
      this.initAudio();
    },
    value:function(val){
      console.log(val)
      switch (val) {
        case '标准日本语初级单词':  this.index =1;break
        case '标准日本语初级课文':  this.index =2;break
        case '耳感记忆N3单词':  this.index =3;break
      }
      localStorage.setItem('VideoListType',val)
      // localStorage.removeItem('videoListIndex')
      this.$store.dispatch('getVideoList',{'type':val})
    }
  },
  computed:{
    ...mapState({
      audio: state=>state.video.videoList
    })
  },
  methods: {
    initAudio() {
      // 创建一个音乐播放器实例，并挂载到DOM上，同时进行相关配置
      const ap = new APlayer({
        container: document.getElementById("aplayer"),
        audio: this.audio, // 音乐信息
        ...this.info, // 其他配置信息
      });

      var that = this
      var val = localStorage.getItem('videoListIndex'+that.index) ?localStorage.getItem('videoListIndex'+that.index) :  0
      console.log(val)
      ap.list.switch(val)
      ap.setMode('normal')
      ap.list.show()

      ap.on('play', function (res) {

        localStorage.setItem('videoListIndex'+that.index,ap.list.index)
        ap.audio.playbackRate = that.multipleValue//设置倍数

        if(that.is_Replay) {
          ap.seek(that.input);
        }
        that.input = ap.audio.currentTime
        // that.timeList.push('开始:'+ap.audio.currentTime)
        // console.log('开始'+ap.audio.currentTime);
      });
      ap.on('pause', function () {
        // that.timeList.push('结束:'+ap.audio.currentTime)
        // console.log('结束'+ap.audio.currentTime);
      });



    },
  },
}
</script>

<style  scoped>
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>