import {getVideo} from "@/api";

//state: 仓库存储数据的地方
const state = {
    videoList:[]
};
//mutations:修改state的唯一手段
const mutations = {
    GETTYPELIST(state,videoList){
        state.videoList = videoList
    }
};
//action:处理action,可以书写自己的业务逻辑，也可以处理异步
const actions= {
    async getVideoList({commit},value){
        console.log(value)
        let res = await getVideo({'type':value.type})

        if(res.code ==200) {
            commit('GETTYPELIST',res.result)
        }
    }
};
//getters:理解为计算履吗性，用于简化仓库数据，让组件获取仓库的数据更加方便
const getters = {};

export default {
    state,mutations,actions,getters
}