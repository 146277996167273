<template>
  <div id="app">
    <Video></Video>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Video from "@/components/Video";
export default {
  name: 'App',
  components: {
    HelloWorld,Video
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
