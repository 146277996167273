//所有api接口
import requests from "@/api/request";

//三级联动接口
///api/product/getBaseCategoryList get无参数

export const getVideo = (query) => {
    console.log(query)
    //发请求
    return requests({url:'/getVideo' ,method: 'post',data:query});
}